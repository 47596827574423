import { Switch } from '@headlessui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// app
import { ISignupForm } from 'interfaces';
import { classNames } from 'utils';
import { AlertError } from '../alert';

import {
  CURRENT_LOGO_URI,
  CURRENT_SITE_NAME,
  IS_ENABLE_SIGNUP_CREATOR,
  BAD_WORD_LIST,
} from 'config';

interface ISignupFormProps {
  error?: string;
  onSubmit: SubmitHandler<ISignupForm>;
  role: string;
  defaultValues?: Partial<ISignupForm>;
}

// const cards = [
//   {
//     id: 1,
//     title: 'Sono un creator',
//     description: 'Posso creare contenuti privati',
//     details: '621 creators',
//   },
//   {
//     id: 2,
//     title: 'Sono un cliente',
//     description: 'Posso acquistare contenuti privati',
//     details: '120000+ utenti',
//   },
// ];

export const SignupForm = (props: ISignupFormProps) => {
  const { onSubmit, error, role, defaultValues } = props;

  const signupFormSchema = yup
    .object({
      username: yup
        .string()
        .max(15)
        .required()
        .test('isBadWord', 'Username non valida', function (value) {
          if (value) {
            // controllo se è stata inserita una bad word
            if (
              BAD_WORD_LIST.includes(value) ||
              BAD_WORD_LIST.some((el) => value.includes(el))
            )
              return false;
          }
          return true;
        })
        .test(
          'hasBlankSpace',
          'Username non può contenere spazi vuoti',
          function (value) {
            if (value) {
              // controllo se è stato inserito uno spazio
              if (/\s/g.test(value)) return false;
            }
            return true;
          }
        )
        .matches(
          /^[a-zA-Z0-9]+$/,
          'Sono consentiti solo caratteri alfanumerici. Non puoi inserire caratteri speciali'
        ),
      email: yup.string().email().required(),
      password: yup.string().min(5, 'Lunghezza minima 6 caratteri').required(),
      isCreator: yup.number().required(),
      hasAdultConsent: yup.boolean().required().isTrue(),
      termsConditionsPrivacyConsent: yup.boolean().required().isTrue(),
    })
    .required();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignupForm>({
    resolver: yupResolver(signupFormSchema),
    defaultValues: {
      isCreator: 2, // default customer
      marketingConsent: false,
      ...defaultValues,
    },
  });

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 ">
        <div className="flex justify-center mb-4">
          <Link to="/">
            <span className="sr-only">{CURRENT_SITE_NAME}</span>
            <img
              className="block h-8 w-auto"
              src={CURRENT_LOGO_URI}
              alt={CURRENT_SITE_NAME}
            />
          </Link>
        </div>
        <div className="divide-y">
          <div className="mb-4">
            {role === 'customer' ? (
              <>
                <p className="flex justify-center text-center prose prose-indigo prose-md text-gray-500 mx-auto">
                  La miglior piattaforma di adult creators contents.
                </p>
                <p className="flex justify-center text-center prose prose-indigo prose-md text-gray-500 mx-auto">
                  Trova il creator adatto a te
                </p>
              </>
            ) : (
              <>
                <p className="font-normal text-gray-800 leading-loose">
                  Registrati per creare la tua pagina personalizzata dove potrai:
                </p>
                <p className="font-bold text-gray-900 leading-loose">
                  • Inserire foto e video Gratis e a pagamento
                </p>
                <p className="font-bold text-gray-900 leading-loose">
                  • Creare il listino dei servizi che offri
                </p>
                <p className="font-bold text-gray-900 leading-loose">
                  • Ricevere chat dai clienti interessati a te
                </p>
                <p className="font-bold text-gray-900 leading-loose">
                  • Farti pagare dai tuoi clienti e/o da quelli di VieniOra.com
                </p>
                <p className="font-normal leading-loose">
                  Per maggiori informazioni puoi leggere le{' '}
                  <Link to="/faqCreator" className="text-indigo-600">
                    FAQ CREATORS
                  </Link>{' '}
                  oppure{' '}
                  <Link to="/support" className="text-indigo-600">
                    scrivici qui
                  </Link>
                </p>
              </>
            )}
          </div>

          <div>
            {role === 'customer' ? (
              <p className="max-w-lg mx-auto text-center font-semibold text-xl mb-2 mt-2 text-pink-500 sm:max-w-3xl">
                Registrazione cliente
              </p>
            ) : (
              <p className="max-w-lg mx-auto text-center font-semibold text-xl mb-2 mt-2 text-pink-500 sm:max-w-3xl">
                Registrazione creator
              </p>
            )}
          </div>
          {error && <AlertError message={error} />}
          <div>
            <form className="space-y-6 mt-8" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    type="username"
                    {...register('username', { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />
                  {errors.username?.type === 'required' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Campo obbligatorio
                    </p>
                  ) : errors.username?.type === 'max' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      La lunghezza massima per la username è di 15 caratteri
                    </p>
                  ) : errors.username?.type === 'isBadWord' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Username non valida
                    </p>
                  ) : errors.username?.type === 'hasBlankSpace' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Username non può contere spazi vuoti
                    </p>
                  ) : errors.username?.type === 'matches' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Sono consentiti solo caratteri alfanumerici. Non è possibile
                      inserire caratteri speciali
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    {...register('email', { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />
                  {errors.email?.type === 'required' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Campo obbligatorio
                    </p>
                  ) : (
                    errors.email && (
                      <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                        Inserire un' email valida
                      </p>
                    )
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    autoComplete="new-password"
                    {...register('password', { required: true, minLength: 6 })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />
                  {errors.password?.type === 'required' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Campo obbligatorio
                    </p>
                  ) : null}
                  {errors.password?.type === 'min' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Lunghezza minima 6 caratteri
                    </p>
                  ) : null}
                </div>
              </div>

              {/* <div className="sm:col-span-3">
                <Controller
                  name="isCreator"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <RadioCards
                        radioGroupLabel="Come vuoi iscriverti?"
                        onChange={onChange}
                        option={cards}
                        value={value}
                      />
                      {errors.isCreator ? (
                        <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                          Campo obbligatorio
                        </p>
                      ) : null}
                    </>
                  )}
                />
              </div> */}

              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-start justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="block text-md font-medium text-gray-700"
                      passive
                    >
                      <span className="block text-sm font-medium text-gray-700">
                        Sono maggiorenne
                      </span>
                    </Switch.Label>
                  </span>

                  <Controller
                    name="hasAdultConsent"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
                {errors.hasAdultConsent ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    È necessario essere maggiorenni
                  </p>
                ) : null}
              </div>

              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-start justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="block text-md font-medium text-gray-700"
                      passive
                    >
                      <span className="block text-sm font-medium text-gray-700">
                        Accetto{' '}
                        <span className="text-sm font-medium text-blue-600 hover:text-blue-500">
                          <Link to="/legal/terms">termini e condizioni</Link>{' '}
                        </span>
                        e{' '}
                        <span className="text-sm font-medium text-blue-600 hover:text-blue-500">
                          <Link to="/legal/privacy">privacy policy</Link>
                        </span>
                      </span>
                    </Switch.Label>
                  </span>

                  <Controller
                    name="termsConditionsPrivacyConsent"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
                {errors.termsConditionsPrivacyConsent ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    È necessario accettare termini, condizioni e privacy policy
                  </p>
                ) : null}
              </div>

              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-start justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="block text-md font-medium text-gray-700"
                      passive
                    >
                      <span className="block text-sm font-medium text-gray-700">
                        Acconsento a ricevere mail su promozioni e novità da vieniora.com
                      </span>
                    </Switch.Label>
                  </span>

                  <Controller
                    name="marketingConsent"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
              </div>

              {/* <div className="flex items-center justify-between">
                <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-pink-600 focus:ring-pink-500 border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div> 
              </div> */}

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Registrati
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-6">
          {/* <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or continue with</span>
            </div>
          </div> */}

          {/* <div className="mt-6 grid grid-cols-3 gap-3">
            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Facebook</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with Twitter</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with GitHub</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="bg-white py-8 px-4 mt-4 shadow sm:rounded-lg sm:px-10 ">
        <div>
          <p className="text-center text-base text-gray-700">
            Hai un account?{' '}
            <Link className="text-base text-pink-600 hover:text-pink-500" to="/login">
              Accedi
            </Link>
          </p>
          {role === 'customer' ? (
            <Link
              to={IS_ENABLE_SIGNUP_CREATOR === true ? '/signup/creator' : '/support'}
              className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              Registrati come creator
            </Link>
          ) : (
            <Link
              to="/signup/customer"
              className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              Registrati come cliente
            </Link>
          )}
        </div>
        {/* <p className="text-center text-base text-gray-700">
          Sei un creator?{' '}
          <Link
            className="text-base text-pink-600 hover:text-pink-500"
            to="/signup/creator"
          >
            Registrati qui
          </Link>
        </p> */}
      </div>
    </div>
  );
};
